import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"

const DownloadsPage = () => (
  <Layout>
    <SEO title="Downloads" />
    <Banner title="Downloaded Files"></Banner>

    <section id="content">
      <div className="content-wrap">
        <div className="container clearfix">
          <div className="col-md-12 col-lg-8 mt-5">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="clearfix" id="abstract">
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <h4 className="float-left vertical-center mb-3">
                        Program At A Glance
                      </h4>
                    </div>
                    <div className="col-md-6 mb-4">
                      <a
                        href="https://drive.google.com/file/d/1rmuIelIsM74SEpJTWW_LXpYcSHpqfObI/view?usp=sharing"
                        target="blank"
                        className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                      >
                        Download Information
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divider divider-center mb-4 mt-2" />

              <div className="col-lg-12">
                <div className="clearfix" id="abstract">
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <h4 className="float-left vertical-center mb-3">
                        Rundown
                      </h4>
                    </div>
                    <div className="col-md-6 mb-4">
                      <a
                        href="https://drive.google.com/file/d/1y_4Dc5gpcF6NL_fyoJlq_K_hNPHNAbq_/view?usp=sharing"
                        target="blank"
                        className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                      >
                        Download Rundown
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divider divider-center mb-4 mt-2" />

              <div className="col-lg-12">
                <div className="clearfix" id="abstract">
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <h4 className="float-left vertical-center mb-3">
                        Template Full Text
                      </h4>
                    </div>
                    <div className="col-md-6 mb-4">
                      <a
                        href="https://drive.google.com/file/d/1HXVeWJzHOf5FqZiReuOaAij_VFlRxruX/view?usp=sharing"
                        target="blank"
                        className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                      >
                        Download Template
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divider divider-center mb-4 mt-2" />

              <div className="col-lg-12">
                <div className="clearfix" id="abstract">
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <h4 className="float-left vertical-center mb-3">
                        Pre-recorded Oral Presentation Guideline
                      </h4>
                    </div>
                    <div className="col-md-6 mb-4">
                      <a
                        href="https://drive.google.com/file/d/1hGTl1b1O6BjeLA34K5BDxcsOFkVFSV14/view?usp=sharing"
                        target="blank"
                        className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                      >
                        Download Guideline
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divider divider-center mb-4 mt-2" />

              <div className="col-lg-12">
                <div className="clearfix" id="abstract">
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <h4 className="float-left vertical-center mb-3">
                        Pre-recorded Poster Presentation Guideline
                      </h4>
                    </div>
                    <div className="col-md-6 mb-4">
                      <a
                        href="https://drive.google.com/file/d/1jvO0V-wh506WS8Caq34w2b-Vrnt7Ez6y/view?usp=sharing"
                        target="blank"
                        className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                      >
                        Download Guideline
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divider divider-center mb-4 mt-2" />

              <div className="col-lg-12">
                <div className="clearfix" id="abstract">
                  <div className="row mb-1">
                    <div className="col-md-6">
                      <h4 className="float-left vertical-center mb-3">
                        (Speakers) Pre-recorded Scientific Presentation
                        Guideline
                      </h4>
                    </div>
                    <div className="col-md-6 mb-4">
                      <a
                        href="https://drive.google.com/file/d/1_69antGa7x-WEU8fbYxchSk4Pns0UnWQ/view?usp=sharing"
                        target="blank"
                        className="button button-reg button-3d button-circle w-100 gradient-grey-orange mr-0 text-center mb-3 submit float-right"
                      >
                        Download Presentation
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="divider divider-center mb-4 mt-2" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default DownloadsPage
